import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import IBreadcrumbTo from '@/module/main/model/IBreadcrumbTo';

export default class CategoryBreadcrumb implements IBreadcrumb {

  public to: IBreadcrumbTo;
  private TEXT_LIMIT: number = 33;
  private SHORTENED_TEXT_SUFFIX: string = '...';

  constructor(
    public text: string,
    public id: number,
    public basePath: string = '/',
  ) {
    this.to = {
      path: `${ this.basePath }${ id }`,
    };
    this.handleLongText();
  }

  private getShortText(text: string): string {
    return `${ text }${ this.SHORTENED_TEXT_SUFFIX }`;
  }

  protected handleLongText(): void {
    const text = this.text.slice(0, this.TEXT_LIMIT);
    this.text = text.length < this.text.length ? this.getShortText(text) : text;
  }
}
