
import Vue from 'vue';
import router from '@/app/router';
import NameEnum from '@/app/router/NameEnum';
import { Component } from 'vue-property-decorator';

@Component
export default class NoProductFound extends Vue {
  private redirectToHomePage(): void {
    router.push({ name: NameEnum.MAIN });
  }
}
