import CategoryBreadcrumb from '@/module/main/model/CategoryBreadcrumb';

const PRODUCT_BASE_PATH: string = '/product/';

export default class ProductBreadcrumb extends CategoryBreadcrumb {

  constructor(
    public text: string,
    public id: number,
  ) {
    super(text, id, PRODUCT_BASE_PATH);
    this.handleLongText();
  }
}
