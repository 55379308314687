import IProduct from '@/module/main/dto/IProduct';
import IProductCategory from '@/module/main/dto/IProductCategory';

export default class ProductDefault implements IProduct {
  constructor(
    public id: number = 0,
    public name: string = '',
    public brand: string = '',
    public seller: string = '',
    public imageUrl: string = '',
    public imageUrlAlternative: string = '',
    public description: string = '',
    public price: number = 0,
    public monthly: number = 0,
    public productUrl: string = '',
    public category: IProductCategory = { id: 0, name: '' },
  ) {}
}
