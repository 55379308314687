
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HomeLayout from '@/app/layouts/HomeLayout.vue';
import NextProducts from '@/components/NextProducts.vue';
import NoProductFound from '@/components/NoProductFound.vue';
import Api from '@/module/api/service/Api';
import SearchResults from '@/components/SearchResults.vue';
import ProductDefault from '@/module/main/dto/ProductDefault';
import IProduct from '@/module/main/dto/IProduct';
import IProducts from '@/module/main/dto/IProducts';
import ProductBreadcrumb from '@/module/main/model/ProductBreadcrumb';
import { mainBreadcrumb } from '@/module/main/model/MainBreadcrumb';
import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import CategoryBreadcrumb from '@/module/main/model/CategoryBreadcrumb';
import { getCulture, getCurrency } from "@/app/utils/EnvConfig";

@Component({
  components: {
    HomeLayout,
    NextProducts,
    SearchResults,
    NoProductFound,
  },
})
export default class Product extends Vue {
  private productId = this.$route.params.id;
  private productData: IProduct | null = new ProductDefault();

  private searching = false;
  private isLoading = true;
  private noProduct = false;
  private searchResults: IProducts | null = null;
  private searchQuery = '';
  private searchNotSuccessful = false;

  private displayMore = false;
  private windowWidth = window.innerWidth;

  private toggleDisplayMore() {
    this.displayMore = !this.displayMore;
  }

  private getBreadcrumbs(product: IProduct): IBreadcrumb[] {
    const productCategory = product.category;
    const crumbs: IBreadcrumb[] = [
      mainBreadcrumb,
    ];

    if (productCategory.parent) {
      crumbs.push(new CategoryBreadcrumb(productCategory.parent.name, productCategory.parent.id));
    }

    crumbs.push(new CategoryBreadcrumb(productCategory.name, productCategory.id));
    crumbs.push(new ProductBreadcrumb(product.name, product.id));

    return crumbs;
  }

  private storeBreadcrumbs(product: IProduct): void {
    if (product.category) {
      this.$store.commit('setBreadcrumbs', this.getBreadcrumbs(product));
    }
  }

  private getProductData() {
    this.isLoading = true;
    window.scrollTo(0, 0);
    Api.product(this.productId).then((response) => {
      this.productData = response;
      this.isLoading = false;
      this.displayMore = false;
      if (this.productData)
        this.storeBreadcrumbs(this.productData);
    }).catch(() => {
      this.isLoading = false;
      this.noProduct = true;
    })
  }

  private getTextLength(): number {
    return this.windowWidth > 991 ? 400 : 200;
  }

  private collectSearchResults(searching: boolean, data: IProducts, query: string) {
    this.searchQuery = query;
    this.isLoading = true;
    this.searching = searching;
    if (data?.data.length > 0) {
      this.searchResults = data;
      this.searchNotSuccessful = false;
      this.isLoading = false;
    } else {
      this.searchNotSuccessful = true;
      this.isLoading = false;
    }
  }

  private redirectToSeller() {
    window.open(
      this.productData ? this.productData.productUrl : '',
      '_blank',
    );
  }

  private collectCategoryData() {
    this.$forceUpdate();
  }

  private loadProductData(productId: number) {
    this.productId = productId.toString();
    this.getProductData();
    window.scrollTo(0, 0);
  }

  private setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  private receiveLoading(loadingLayout: boolean) {
    this.isLoading = loadingLayout;
  }

  private getFormatedPrice(price: number): string {
    return new Intl.NumberFormat(getCulture(), {
      currency: getCurrency(),
      style: 'currency',
      maximumSignificantDigits: 6,
    }).format(price);
  }

  private get logo() {
    return this.$store.state.shops.find((img: any) => img.name === this.productData?.seller)?.logo;
  }

  created() {
    this.getProductData();
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
