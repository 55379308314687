import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import IBreadcrumbTo from '@/module/main/model/IBreadcrumbTo';
import vue from '@/main';

export default class MainBreadcrumb implements IBreadcrumb {

  constructor(
    public text: string = vue.$localize('home'),
    public to: IBreadcrumbTo = {
      name: 'Hlavní kategorie',
    },
  ) {
  }
}

export const mainBreadcrumb = new MainBreadcrumb();
